(function ($) {
	$(function () {
		let $items = $("<ul></ul>")

		$(".mainvisual #slider div").each(function () {
			let $item = $('<li></li>');

			$item.append($(this).children("img"));
			$items.append($item);
		})

		$(".mainvisual").html($items);

		$(".mainvisual").addClass("swiper");
		$(".mainvisual > ul").addClass("swiper-wrapper");
		$(".mainvisual > ul > li").addClass("swiper-slide");

		let swiper = new Swiper(".mainvisual", {
			loop: true,
			effect: 'fade',
			speed: 6000,
			autoplay: {
				delay: 0,
			},
			on: {
				slideChangeTransitionStart: function () {
					mySwiperWrapper.style.transitionTimingFunction = 'linear';
				},
				resize: function () {
					mySwiper.autoplay.start();
				}
			}
		});

		$(".slider").addClass("swiper");
		$(".slider > ul").addClass("swiper-wrapper");
		$(".slider > ul > li").addClass("swiper-slide");

		let swiper2 = new Swiper(".slider", {
			speed: 8000,
			slidesPerView: "auto",
			loop: true,
			freeMode: true,
			freeModeMomentum: false,
			slidesPerView: 2,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
			},
			breakpoints: {
				969: {
					slidesPerView: 5,
				}
			},
			on: {
				slideChangeTransitionStart: function () {
					mySwiperWrapper.style.transitionTimingFunction = 'linear';
				},
				resize: function () {
					mySwiper.autoplay.start();
				}
			}
		});
	});
})(jQuery);